import React from 'react'
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useInvoicesList } from '../../components/hooks/invoices';
import PagesTable from './Comp/Table';
import { useSeoPagesList } from '../../components/hooks/seo';
import { useBlogPostsList } from '../../components/hooks/posts';

export default function BlogPosts() {
    const { user } = useUser();
    const { postsData, isLoadingPosts, filterPosts } = useBlogPostsList();
    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper mt-2">
                <div className="card mx-2">

                    <PagesTable data={postsData} isLoading={isLoadingPosts} />
                </div>

            </div>
        </div>
    )
}
