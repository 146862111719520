import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../utils/core';

export default function SeoPageInfoModal({ modal, setModal, data }) {
    let navigate = useNavigate();

    if (!data) {
        return null; // Return null if data is not available
    }

    return (
        <div className={`modal fade ${modal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-info">Page Info</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <p><strong>Title:</strong> {data.metaTitle}</p>
                        <p><strong>Banner Image:</strong> <img src={data.bannerImage} alt={data.metaTitle} style={{ maxWidth: '100%' }} /></p>
                        <p><strong>Canonical Tag:</strong> {data.canonicalTag}</p>
                        <p><strong>Content:</strong> {data.content}</p>
                        <p><strong>Meta Descriptions:</strong> {data.metaDescriptions}</p>
                        <p><strong>Meta Keywords:</strong> {data.metaKeywords}</p>
                        <p><strong>Page Slug:</strong> {data.pageSlug}</p>
                        <p><strong>Schema Markup:</strong> {data.schemaMarkup}</p>
                        <p><strong>Created At:</strong> {formatDateTime(data.createdAt)}</p>
                        <p><strong>Updated At:</strong> {formatDateTime(data.updatedAt)}</p>
                        <p><strong>Published:</strong> {data.isPublished ? 'Yes' : 'No'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}