import React, { useState } from 'react';
import { Typography, Switch, FormControlLabel } from '@mui/material';
import SelectField from '../../../components/Common/SelectField';
import FormikDatePicker from '../../../components/Common/FormikDatePicker';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { stripResturantsList, useRestaurantsList } from '../../../components/hooks/restaurants';
import { LoadingButton } from '@mui/lab';
import TextField from '../../../components/Common/Fields';
import MyEditor from '../../../components/Common/MDXEditor';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 2 * 1024 * 1024; // 2MB

// Validation schema
const validationSchema = Yup.object().shape({
    file: Yup.mixed()
        .required('Banner image is required')
        .test(
            'fileSize',
            'File size is too large',
            value => !value || (value && value.size <= FILE_SIZE)
        )
        .test(
            'fileFormat',
            'Unsupported file format',
            value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
    altText: Yup.string().required('Alt text is required'),
    // isActive: Yup.boolean().required('Active status is required'),
});

export default function MainForm({ data, handleFormSubmit, btnLabel, isLoading }) {
    const [preview, setPreview] = useState(null);

    return (
        <Formik
            initialValues={{
                file: null,
                altText: data?.altText ?? 'Banner Image',
                isActive: data?.isActive ?? true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {formik => (
                <Form>
                    <div className='row mb-3'>
                        <label>Banner Image</label>
                        {/* Image Preview */}
                        {preview && (
                            <center>
                                <div className="col-md-12 my-2">
                                    <img
                                        src={preview}
                                        alt="Preview"
                                        className='img-thumbnail rounded img-fluid'
                                        style={{ width: "300px", height: "100px", objectFit: "cover" }}
                                    />
                                </div>
                            </center>
                        )}
                        <div className="col-md-12">
                            <input
                                id="file"
                                name="file"
                                type="file"
                                onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    formik.setFieldValue("file", file);

                                    // Set preview
                                    if (file) {
                                        const reader = new FileReader();
                                        reader.onload = () => setPreview(reader.result);
                                        reader.readAsDataURL(file);
                                    } else {
                                        setPreview(null);
                                    }
                                }}
                                className="form-control"
                            />
                            <ErrorMessage name="file" component="div" className="text-danger" />
                        </div>



                        <div className="col-md-12 mt-2">
                            <label>Banner Alt Text</label>
                            <TextField
                                label="Alt Text"
                                name="altText"
                                placeholder="Alt Text"
                                sx={{ mt: 3 }}
                            />
                            <ErrorMessage name="altText" component="div" className="text-danger" />
                        </div>
                        {/* <div className="col-md-12 mt-2">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.isActive}
                                        onChange={(event) => formik.setFieldValue("isActive", event.target.checked)}
                                        name="isActive"
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                            <ErrorMessage name="isActive" component="div" className="text-danger" />
                        </div> */}
                    </div>

                    <div className='d-flex justify-content-center align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                {btnLabel ? btnLabel : "Save record"}
                            </LoadingButton>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}