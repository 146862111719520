import "@mdxeditor/editor/style.css";
import { codeBlockPlugin, codeMirrorPlugin, diffSourcePlugin, directivesPlugin, frontmatterPlugin, headingsPlugin, imagePlugin, linkDialogPlugin, linkPlugin, listsPlugin, markdownShortcutPlugin, quotePlugin, thematicBreakPlugin, tablePlugin, toolbarPlugin, BoldItalicUnderlineToggles, CreateLink, InsertImage, ListsToggle, InsertTable, InsertCodeBlock, InsertThematicBreak, BlockTypeSelect } from '@mdxeditor/editor';
import React, { Fragment } from 'react';
import { MDXEditor } from "@mdxeditor/editor";
import { useField } from 'formik';
import toast from "react-hot-toast";
import { useUploadImage } from "../../hooks/seo";

export default function MyEditor({ label, name }) {
    const { uploadImage, isUploading } = useUploadImage();
    const [isLoading, setLoading] = React.useState(false);
    const [field, , helpers] = useField(name);
    const { setValue } = helpers;


    async function imageUploadHandler(image) {
        setLoading(true);
        const toastId = toast.loading('Uploading...');
        const formData = new FormData();
        formData.append('images', image);

        // formData.append('source', props?.data?.source);

        try {
            const response = await uploadImage(formData);
            if (response.success) {
                toast.success('Uploaded!', { id: toastId });
                setLoading(false);
                // Return the image URL
                return response.data; // Assuming the URL is returned as `response.data.url`
            } else {
                toast.error(response?.error, { id: toastId });
            }
        } catch (error) {
            toast.error('Upload failed.', { id: toastId });
        } finally {
            setLoading(false);
        }
        return null; // Return null on failure
    }

    const plugins = [
        toolbarPlugin({
            toolbarContents: () => (
                <Fragment>
                    <BoldItalicUnderlineToggles />
                    <BlockTypeSelect />
                    <CreateLink />
                    <InsertImage />
                    <ListsToggle />
                    <InsertTable />
                    <InsertCodeBlock />
                    <InsertThematicBreak />
                </Fragment>
            )
        }),
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        imagePlugin({ disableResizing: true, imageUploadHandler }),
        tablePlugin(),
        thematicBreakPlugin(),
        frontmatterPlugin(),
        markdownShortcutPlugin(),
        codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
        codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS', txt: 'text', tsx: 'TypeScript' } }),
        directivesPlugin(),
        diffSourcePlugin({ viewMode: 'rich-text' }),
    ];

    const handleChange = (markdown) => {
        if (typeof markdown === "string") {
            setValue(markdown.trim());
        }
    };

    return (
        <div>
            <label className="label">{label}</label>

            {plugins.length > 0 ? (
                <MDXEditor
                    className='mdxeditor-rich-text-editor bg-light border border-light'

                    contentEditableClassName="prose antialiased prose-a:text-primary prose-a:no-underline prose-a:font-semibold prose-a:cursor-pointer prose-code:text-red-600 max-w-none prose-img:rounded-xl prose-blockquote:font-medium"
                    // contentEditableClassName="text-body text-primary text-decoration-none fw-semibold cursor-pointer text-danger w-100 rounded-3 blockquote fw-medium"

                    plugins={plugins}
                    markdown={field.value || ""}
                    onChange={handleChange}
                    toMarkdownOptions={{
                        bullet: '+',
                        emphasis: '_'
                    }}
                />
            ) : (
                <p>Loading editor...</p>
            )}
        </div>
    );
}
