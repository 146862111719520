import { Route, Routes } from "react-router-dom";
import MainPage from "../pages";
import RestPage from "../pages/resturants/RestPage";
import AddRestaurantPage from "../components/ResturantContent/add";
import LoginPage from "../pages/account/Login";
import ProtectedRoutes from "./ProtectedRoutes";
import RestaurantDetailPage from "../pages/resturants/RestaurantDetailPage";
import Vendor from "../pages/vendor/Vendor";
import StationsPage from "../pages/station/StationsPage";
import EnquiryPage from "../pages/enquiry/Enquiry";
import OrderPage from "../pages/order/OrderPage";
import OrderDetailPage from "../components/Order/OrderDetailPage";
import FeaturePage from "../pages/feature/FeaturePage";
import FeaturedItemForm from "../components/form/FeaturedItemForm";
import CustomerPage from "../pages/customer/CustomerPage";
import User from "../pages/user/User";
import Cusine from "../pages/cusineType/Cusine";
import FoodType from "../pages/foodtType/FoodType";
import AddBlog from "../components/form/AddBlog";
import Blog from "../pages/blog/Blog";
import Location from "../pages/locations/Location";
import AddLocation from "../components/form/AddLocation";
import BlogUpdate from "../components/BlogComponent/BlogUpdate";
import CancelReq from "../pages/cancelReq/Cancel";
import DynamicPage from "../pages/dynamicPages/DynamicPage";
import AddPage from "../components/form/AddPage";
import UpdatePage from "../components/PagesComponent/UpdatePage";
import BookOrder from "../pages/book-order/BookOrder";
import Menu from "../pages/menu/Menu";
import VendorRds from "../pages/vendor-rds/VendorRds";
import VendorTransactionsPage from "../pages/VendorTransactionsPage";
import InvoicesListPage from "../pages/invoices/list";
import CreateInvoicePage from "../pages/invoices/create";
import UpdateInvoicePage from "../pages/invoices/update";
import InvoiceDetailPage from "../pages/invoices/detail";
import SEOManagementPage from "../pages/seo-management/page";
import UpdatePost from "../pages/posts/update";
import BlogPosts from "../pages/posts/list";
import NewPost from "../pages/posts/add";
import BannersManagementPage from "../pages/banner-management/page";
import SEOUpdatePage from "../pages/seo-management/update";

export default function AppRoutes() {
 

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route index element={<MainPage />} />
        <Route path="/restuarants" element={<RestPage />} />
        <Route path="/restaurants/add" element={<AddRestaurantPage />} />
        <Route path="/restaurants/:id" element={<RestaurantDetailPage />} />
        
        <Route path="/invoices" element={<InvoicesListPage />} />
        <Route path="/invoices/create" element={<CreateInvoicePage />} />
        <Route path="/invoices/update/:id" element={<UpdateInvoicePage />} />
        <Route path="/invoices/:id" element={<InvoiceDetailPage />} />

        <Route path="/seo-management" element={<SEOManagementPage />} />
        <Route path="/seo-management/:slug" element={<SEOUpdatePage />} />
        {/* <Route path="/invoices/create" element={<CreateInvoicePage />} />
        <Route path="/invoices/update/:id" element={<UpdateInvoicePage />} />
        <Route path="/invoices/:id" element={<InvoiceDetailPage />} /> */}

        <Route path="/vendor" element={<Vendor />} />
        <Route path="/vendor-rds" element={<VendorRds />} />
        <Route path="/vendor-transactions" element={<VendorTransactionsPage />} />
        <Route path="/stations" element={<StationsPage />} />
        <Route path="/enquiry" element={<EnquiryPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/orders/:id" element={<OrderDetailPage />} />
        
        <Route path="/banners-management" element={<BannersManagementPage />} />

        <Route path="/posts" element={<BlogPosts />} />
        <Route path="/posts/create" element={<NewPost />} />
        <Route path="/posts/:slug" element={<UpdatePost />} />


        <Route path="/featureitem" element={<FeaturePage />} />
        <Route path='/addfeatureitem' element={<FeaturedItemForm />} />  
        <Route path='/customers' element={<CustomerPage/>} />  
        <Route path='/users' element={<User/>} />  
        <Route path='/cusine' element={<Cusine/>} />  
        <Route path='/foodtype' element={<FoodType/>} />  
        <Route path='/addblog' element={<AddBlog/>} />  
        <Route path='/blog' element={<Blog/>} />  
        <Route path='/location' element={<Location/>} />  
        <Route path='/addlocation' element={<AddLocation/>} />  
        <Route path='/update-blog/:id' element={<BlogUpdate/>} />  
        <Route path='/cancel-req' element={<CancelReq/>} />  
        <Route path='/pages' element={<DynamicPage/>} />  
        <Route path='/addpages' element={<AddPage/>} />  
        <Route path='/book-order' element={<BookOrder/>} />  
        <Route path='/update-page/:slug/:id' element={<UpdatePage/>} />  
        <Route path='/menu/:id/' element={<Menu/>} />  
      </Route>

      <Route>
        <Route path="/account/login"  element={ <LoginPage />} />
      </Route>
      <Route path="*" element={<h1>404 Page not found</h1>} />
    </Routes>
  );
}
