import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../../utils/core';
import MainForm from './MainForm';
import { useCreateSEOPage } from '../../../components/hooks/seo';
import { toast } from 'react-toastify';
import { useCreateBanner } from '../../../components/hooks/banners';

export default function AddPageModal({ modal, setModal }) {
    const { handleCreate, isCreating } = useCreateBanner();
    let navigate = useNavigate();
    const handleFormSubmit = async (values) => {

        const res = await handleCreate(values);
        console.log(res)

        if (res.success) {
            setModal(false);
            toast.success('Record added successfully');
            navigate('/banners-management');
        } else {
            toast.error('Failed to add record');
        }

    };

    return (
        <div className={`modal fade ${modal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-info">Add Record</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <MainForm handleFormSubmit={handleFormSubmit} isLoading={isCreating} />
                    </div>
                </div>
            </div>
        </div>
    );
}