import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { useState } from "react";
import axios from "axios";


const SEOPAGE_QUERY_KEY = 'seo_pages';
const SEOPAGE_DETAIL_QUERY_KEY = 'seo_page_detail';
// const SINGLE_PAGE_QUERY_KEY = 'seo_pages';
// const INVOICE_DATA_QUERY_KEY = 'invoice_data';
// const INVOICE_QUERY_KEY = 'invoice';

export function useSeoPagesList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([SEOPAGE_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            console.log(`${API_HOST_URL}/${endpoints.seo.list}${param_url}`)
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.seo.list}${param_url}`, {
                // const res = await fetchJson(`${API_HOST_URL}/${endpoints.seo.list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, error: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        // refetchOnWindowFocus: true,
        // refetchOnMount: true,
        enabled: enabled,
    })
    return {
        seoPagesData: data,
        isLoadingSeoPages: isLoading,
        filterSeoPages: async (value) => setParam(value),
    }
}
export function useSeoPagesDetailList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([SEOPAGE_DETAIL_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            console.log(`${API_HOST_URL}/${endpoints.seo.detail}${param_url}`)
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.seo.detail}${param_url}`, {
                // const res = await fetchJson(`${API_HOST_URL}/${endpoints.seo.list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, error: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        // refetchOnWindowFocus: true,
        // refetchOnMount: true,
        enabled: enabled,
    })
    return {
        seoPagesData: data,
        isLoadingSeoPages: isLoading,
        filterSeoPages: async (value) => setParam(value),
    }
}

export function useCreateSEOPage() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => {
        const formData = new FormData();
        for (const key in values) {
            if (key === 'bannerImage') {
                continue;
            }
            formData.append(key, values[key]);
        }
        if (values.bannerImage) {
            formData.append('bannerImage', values?.bannerImage);
        }
        // return axios.post(`${API_HOST_URL}/${endpoints.seo.create}`, formData, {
        return axios.post(`${API_HOST_URL}/${endpoints.seo.create}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }, {
        retry: 2,
    });

    return {
        handleCreatePage: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = res.data;
                if (data.success) {
                    await queryClient.invalidateQueries([SEOPAGE_QUERY_KEY]);
                }
                return data;
            } catch (err) {
                console.log(err);
                return {
                    success: false,
                    error: err
                };
            }
        },
        isCreatingPage: mutation.isLoading,
    };
}
export function useUpdateSEOPage() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => {
        const formData = new FormData();
        for (const key in values) {
            if (key === 'bannerImage') {
                continue;
            }
            formData.append(key, values[key]);
        }
        if (values.bannerImage) {
            formData.append('bannerImage', values?.bannerImage);
        }
        // return axios.post(`${API_HOST_URL}/${endpoints.seo.create}`, formData, {
        return axios.put(`${API_HOST_URL}/${endpoints.seo.update}/${values.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }, {
        retry: 2,
    });

    return {
        handleUpdatePage: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = res.data;
                if (data.success) {
                    await queryClient.invalidateQueries([SEOPAGE_QUERY_KEY]);
                }
                return data;
            } catch (err) {
                console.log(err);
                return {
                    success: false,
                    error: err
                };
            }
        },
        isUpdatingPage: mutation.isLoading,
    };
}


export function useUploadImage() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((formData) => {
      
        return axios.post(`${API_HOST_URL}/${endpoints.core.upload_image}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }, {
        retry: 2,
    });

    return {
        uploadImage: async (formData) => {
            try {
                const res = await mutation.mutateAsync(formData);
                const data = res.data;
                if (data.success) {
                    await queryClient.invalidateQueries([SEOPAGE_QUERY_KEY]);
                }
                return data;
            } catch (err) {
                console.log(err);
                return {
                    success: false,
                    error: err
                };
            }
        },
        isUploading: mutation.isLoading,
    };
}
