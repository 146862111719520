import React from 'react'
import Header from '../../components/Header/Header'
import Sidenav from '../../components/Sidenav/Sidenav'
import { useUser } from '../../components/hooks/user';
import AddPostForm from './Comp/AddPostForm';
import { useNavigate } from 'react-router-dom';
import { useCreateSEOPage } from '../../components/hooks/seo';
import { toast } from 'react-toastify';
import { useCreatePost } from '../../components/hooks/posts';

export default function NewPost() {
    const { user } = useUser();

    const { handleCreatePost, isCreatingPost } = useCreatePost();
    let navigate = useNavigate();
    
    const handleFormSubmit = async (values) => {
        const res = await handleCreatePost(values);

        if (res.success) {
            // setModal(false);
            toast.success('Record added successfully');
            navigate('/posts');
        } else {
            toast.error('Failed to add record');
        }

    };

    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper mt-2">
                <div className="card mx-2 p-4">
                    <h3 className='mb-3' >Add new post</h3>
                    <AddPostForm 
                        handleFormSubmit={handleFormSubmit} 
                        isLoading={isCreatingPost}
                    />
                </div>

            </div>
        </div>
    )
}
