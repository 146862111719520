import React from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../utils/core';
// import SeoPageInfoModal from './InfoModal';
// import AddPageModal from './AddPageModal';
// import EditPageModal from './EditPageModal';
// import MyEditor from '../../../components/Common/MDXEditor';
// import InvoicePrinter from './InvoicePrinter';

export default function PagesTable({ data, isLoading, searchParams, setSearchParams }) {
    data = data?.data;
    const [currentData, setCurrentData] = React.useState(null);
    const [viewModal, setViewModal] = React.useState(false);
    const [addModal, setAddModal] = React.useState(false);
    const [editModal, setEditModal] = React.useState(false);



    // console.log(data)

    const columns = [

        {
            name: "Title",
            selector: (row) => row._id,
            cell: (row) => <Link to={`/posts/${row.pageSlug}`} className='text-primary' role='button'>{row.pageTitle}</Link>,
            sortable: true,
            width: "30%",
        },
        {
            name: "Slug",
            selector: (row) => row?.pageSlug,
            sortable: true,
            width: "20%",
        },

        {
            name: "Created at",
            selector: (row) => formatDateTime(row?.createdAt, 'DD/MM/YYYY hh:mm A'),
            sortable: true,
            width: "15%",
        },
        {
            name: "Updated at",
            selector: (row) => formatDateTime(row?.updatedAt, 'DD/MM/YYYY hh:mm A'),
            sortable: true,
            width: "15%",
        },
        {
            name: "Actions",
            button: true,
            width: "20%",
            cell: (row) => (
                <div className="d-flex gap-1">
                    {/* <button onClick={() => { setCurrentData(row); setViewModal(true); }} className='btn btn-warning pb-0 btn-style-light  btn-sm' title='View'>
                        <span className="material-symbols-outlined">
                            visibility
                        </span>
                    </button> */}
                    <Link to={`/posts/${row.pageSlug}`} className='btn btn-sm btn-warning pb-0 btn-style-light  btn-sm' title='Edit'>
                        <span className="material-symbols-outlined">
                            edit
                        </span>
                    </Link>

                    {/* {viewModal && <SeoPageInfoModal
                        modal={viewModal}
                        setModal={setViewModal}
                        data={currentData}
                    />}

                    {/*  */}
                    {/* {editModal && <EditPageModal
                        data={currentData}
                        modal={editModal}
                        setModal={setEditModal}
                    />} */}
                    {/* {printInvoice && <InvoicePrinter onPrintComplete={handlePrintComplete} data={currentData} />} */}
                </div>
            )
        }
    ];

    const subHeaderComponent = React.useMemo(() => {
        return (
            <div className="d-flex gap-2">
                <Link to='/posts/create' type='button' className="btn btn-primary btn-sm d-flex align-items-center" onClick={() => setAddModal(true)}>
                    <i className="material-icons">add</i> <span className="ml-1">New</span>
                </Link>

            </div>
        );
    }, []);

    React.useEffect(() => {
        // console.log('addModal state:', addModal);
    }, [addModal]);

    // console.log('data', data)
    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                // progressPending={isLoading}
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
                subHeader
                title='List of Blog Posts'
            />
            {/* {addModal && <AddPageModal
                modal={addModal}
                setModal={setAddModal}
            />} */}

            {/* <MyEditor /> */}
        </div>
    )
}
