import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/core";
import { API_HOST_URL } from "../../config";
import { useState } from "react";
import axios from "axios";


const POSTSPAGE_QUERY_KEY = 'blog_posts';

export function useBlogPostsList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([POSTSPAGE_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.posts.list}${param_url}`, {
                // const res = await fetchJson(`${API_HOST_URL}/${endpoints.seo.list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, error: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        // refetchOnWindowFocus: true,
        // refetchOnMount: true,
        enabled: enabled,
    })
    return {
        postsData: data,
        isLoadingPosts: isLoading,
        filterPosts: async (value) => setParam(value),
    }
}

export function useCreatePost() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => {
        const formData = new FormData();
        for (const key in values) {
            if (key === 'images') {
                continue;
            }
            formData.append(key, values[key]);
        }
        if (values.images) {
            formData.append('images', values?.images);
        }
        // return axios.post(`${API_HOST_URL}/${endpoints.seo.create}`, formData, {
        return axios.post(`${API_HOST_URL}/${endpoints.posts.list}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }, {
        retry: 2,
    });

    return {
        handleCreatePost: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = res.data;
                if (data.success) {
                    await queryClient.invalidateQueries([POSTSPAGE_QUERY_KEY]);
                }
                return data;
            } catch (err) {
                console.log(err);
                return {
                    success: false,
                    error: err
                };
            }
        },
        isCreatingPost: mutation.isLoading,
    };
}
export function useUpdatePost() {
    const queryClient = useQueryClient();
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => {
        const formData = new FormData();
        for (const key in values) {
            if (key === 'images') {
                continue;
            }
            formData.append(key, values[key]);
        }
        if (values.images) {
            formData.append('images', values?.images);
        }
        // return axios.post(`${API_HOST_URL}/${endpoints.seo.create}`, formData, {
        return axios.put(`${API_HOST_URL}/${endpoints.posts.list}/${values.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }, {
        retry: 2,
    });

    return {
        handleUpdatePost: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const data = res.data;
                if (data.success) {
                    await queryClient.invalidateQueries([POSTSPAGE_QUERY_KEY]);
                }
                return data;
            } catch (err) {
                console.log(err);
                return {
                    success: false,
                    error: err
                };
            }
        },
        isUpdatingPost: mutation.isLoading,
    };
}
