import React from 'react'
import Header from '../../components/Header/Header'
import Sidenav from '../../components/Sidenav/Sidenav'
import { useUser } from '../../components/hooks/user';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SEOForm from './Comp/SEOForm';
import { useSeoPagesDetailList, useSeoPagesList, useUpdateSEOPage } from '../../components/hooks/seo';

export default function SEOUpdatePage() {
    const { user } = useUser();
    const { slug } = useParams();
    console.log(slug)
    const { seoPagesData, isLoadingSeoPages, filterSeoPages } = useSeoPagesDetailList(`/${slug}`);
    console.log(seoPagesData)
    const { handleUpdatePage, isUpdatingPage } = useUpdateSEOPage();
    
    let navigate = useNavigate();

    const handleFormSubmit = async (values) => {
        // if (postsData?.data?.pageSlug){
        //     alert('Record not found');
        //     return;
        // }

        const res = await handleUpdatePage({
            ...values,
            id: seoPagesData?.data?._id,
        });

        if (res.success) {
            // setModal(false);
            toast.success('upadted successfully');
            navigate('/seo-management');
        } else {
            toast.error('Failed to add record');
        }

    };

    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper mt-2">
                <div className="card mx-2 p-4">
                    <h3 className='mb-3' >Update post</h3>
                    {seoPagesData && !isLoadingSeoPages && <SEOForm
                        data={seoPagesData?.data ?? {}}
                        handleFormSubmit={handleFormSubmit}
                        isLoading={isUpdatingPage}
                    />}
                </div>

            </div>
        </div>
    )
}
