import React from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../utils/core';
import SeoPageInfoModal from './InfoModal';
import AddPageModal from './AddPageModal';
import EditPageModal from './EditPageModal';
import MyEditor from '../../../components/Common/MDXEditor';
// import InvoicePrinter from './InvoicePrinter';

export default function PagesTable({ data, isLoading, searchParams, setSearchParams }) {
    data = data?.data;
    const [currentData, setCurrentData] = React.useState(null);
    const [viewModal, setViewModal] = React.useState(false);
    const [addModal, setAddModal] = React.useState(false);
    const [editModal, setEditModal] = React.useState(false);



    // console.log(data)

    const columns = [
        {
            name: "Page Slug",
            selector: (row) => row._id,
            cell: (row) => <span className='text-primary' role='button' onClick={() => { setCurrentData(row); setViewModal(true); }}>{row.pageSlug}</span>,
            sortable: true,
        },


        {
            name: "Created at",
            selector: (row) => formatDateTime(row?.createdAt, 'DD/MM/YYYY hh:mm A'),
            sortable: true,
            // minWidth: "180px",
        },
        {
            name: "Updated at",
            selector: (row) => formatDateTime(row?.updatedAt, 'DD/MM/YYYY hh:mm A'),
            sortable: true,
            // minWidth: "180px",
        },
        {
            name: "Actions",
            button: true,
            width: "300px",
            cell: (row) => (
                <div className="d-flex gap-1">
                    <button onClick={() => { setCurrentData(row); setViewModal(true); }} className='btn btn-warning pb-0 btn-style-light  btn-sm' title='View'>
                        <span className="material-symbols-outlined">
                            visibility
                        </span>
                    </button>
                    {/* <button onClick={() => { setCurrentData(row); setEditModal(true); }} className='btn btn-warning pb-0 btn-style-light  btn-sm' title='Edit'>
                        <span className="material-symbols-outlined">
                            edit
                        </span>
                    </button> */}
                    <Link to={`/seo-management/${row.pageSlug}`} className='btn btn-warning pb-0 btn-style-light  btn-sm' title='Edit'>
                        <span className="material-symbols-outlined">
                            edit
                        </span>
                    </Link>


                    {/* {printInvoice && <InvoicePrinter onPrintComplete={handlePrintComplete} data={currentData} />} */}
                </div>
            )
        }
    ];

    const subHeaderComponent = React.useMemo(() => {
        return (
            <div className="d-flex gap-2">
                <button type='button' className="btn btn-primary btn-sm d-flex align-items-center" onClick={() => setAddModal(true)}>
                    <i className="material-icons">add</i> <span className="ml-1">New</span>
                </button>

            </div>
        );
    }, []);

    React.useEffect(() => {
        // console.log('addModal state:', addModal);
    }, [addModal]);

    // console.log('data', data)
    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                // progressPending={isLoading}
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
                subHeader
                title='List of SEO Pages'
            />
            {addModal && <AddPageModal
                modal={addModal}
                setModal={setAddModal}
            />}

            {viewModal && <SeoPageInfoModal
                modal={viewModal}
                setModal={setViewModal}
                data={currentData}
            />}

            {/*  */}
            {editModal && <EditPageModal
                data={currentData}
                modal={editModal}
                setModal={setEditModal}
            />}

            {/* <MyEditor /> */}
        </div>
    )
}
