export const handleExportMenu = async (name, id) => {

    if (!id || !name) {
        return;
    }
    
    const url = `https://api.yatrirestro.com/api/v1/item/download/${id}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${name} - Menus.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};


