import React from 'react';
import { Typography } from '@mui/material';
import SelectField from '../../../components/Common/SelectField';
import FormikDatePicker from '../../../components/Common/FormikDatePicker';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { stripResturantsList, useRestaurantsList } from '../../../components/hooks/restaurants';
import { LoadingButton } from '@mui/lab';
import TextField from '../../../components/Common/Fields';
import MyEditor from '../../../components/Common/MDXEditor';
// import MyEditor from '../../../components/Common/MDXEditor';
// import axios from 'axios';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 2 * 1024 * 1024; // 2MB

const validationSchema = Yup.object().shape({
    bannerImage: Yup.mixed()
        .test('fileSize', 'File too large', value => !value || (value && value.size <= FILE_SIZE))
        .test('fileFormat', 'Unsupported Format', value => !value || (value && SUPPORTED_FORMATS.includes(value.type))).nullable(),
    altText: Yup.string(),
    metaTitle: Yup.string().required('Meta Title is required'),
    metaKeywords: Yup.string(),
    metaDescriptions: Yup.string().required('Meta Descriptions is required'),
    pageSlug: Yup.string()
        .matches(/^[a-z0-9-]+$/, 'Slug can only contain lowercase letters, numbers, and hyphens')
        .required('Slug is required'),
    canonicalTag: Yup.string(),
    schemaMarkup: Yup.string(),
    content: Yup.string(),
});

export default function SEOForm({ data, handleFormSubmit, btnLabel, isLoading }) {



    return (
        <Formik
            initialValues={{
                bannerImage: null,
                altText: data?.altText ?? '',
                metaTitle: data?.metaTitle ?? '',
                metaKeywords: data?.metaKeywords ?? '',
                metaDescriptions: data?.metaDescriptions ?? '',
                pageSlug: data?.pageSlug ?? '',
                canonicalTag: data?.canonicalTag ?? '',
                schemaMarkup: data?.schemaMarkup ?? '',
                content: data?.content ?? '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-12">
                            <label>Page Slug</label>
                            <TextField
                                label="Page Slug"
                                name="pageSlug"
                                placeholder="Page Slug"
                            />
                        </div>
                        <div className="col-md-12">
                            <label>Meta Title</label>
                            <TextField
                                label="Meta Title"
                                name="metaTitle"
                                placeholder="Meta Title"
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className="col-md-12">
                            <label>Meta Descriptions</label>
                            <TextField
                                label="Meta Keywords"
                                name="metaKeywords"
                                placeholder="Meta Keywords"
                                sx={{ mb: 3 }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>Meta Descriptions</label>
                            <TextField
                                label="Meta Descriptions"
                                name="metaDescriptions"
                                placeholder="Meta Descriptions"
                                sx={{ mb: 3 }}
                            />
                        </div>
                    </div>

                    <div className='row'>

                        <div className="col-md-12">
                            <label>Canonical Tag</label>
                            <TextField
                                label="Canonical Tag"
                                name="canonicalTag"
                                placeholder="Canonical Tag"
                                sx={{ mb: 3 }}
                            />
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className="col-md-12">
                            <label>Schema Markup</label>
                            <TextField
                                label="Schema Markup"
                                name="schemaMarkup"
                                placeholder="Schema Markup"
                                sx={{ mb: 3 }}
                            />
                        </div>
                        <div className="col-md-12">
                            {/* <TextField
                                label="Content"
                                name="content"
                                placeholder="Content"
                                multiline
                                rows={4}
                                sx={{ mb: 3 }}
                            /> */}
                            <MyEditor
                                label="Content"
                                name="content"
                                placeholder="Content"

                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <label>Banner Image</label>
                        <div className="col-md-12">
                            <input
                                id="bannerImage"
                                name="bannerImage"
                                type="file"
                                onChange={(event) => {
                                    formik.setFieldValue("bannerImage", event.currentTarget.files[0]);
                                }}
                                className="form-control"
                            />
                            <ErrorMessage name="bannerImage" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-12 mt-2">
                            <label>Banner Alt Text</label>
                            <TextField
                                label="Alt Text"
                                name="altText"
                                placeholder="Alt Text"
                                sx={{ mt: 3 }}
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                {btnLabel ? btnLabel : "Save record"}
                            </LoadingButton>
                            {/* <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => formik.resetForm()}>Clear Filter</Button> */}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}