import React from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../utils/core';
import InvoicePrinter from './InvoicePrinter';

export default function InvoicesTable({ data, isLoading, searchParams, setSearchParams }) {
    data = data?.data;
    const [currentData, setCurrentData] = React.useState(null);
    const [printInvoice, setPrintInvoice] = React.useState(false);

    const handlePrintClick = () => {
        setPrintInvoice(true);
    };

    const handlePrintComplete = () => {
        setPrintInvoice(false);
        setCurrentData(null)
    };
    // console.log(data)

    const columns = [
        {
            name: "Invoice ID",
            selector: (row) => row._id,
            cell: (row) => <Link to={`/orders/${row._id}`}>{row.invNo}</Link>,
            sortable: true,
        },
        {
            name: "Restaurant Name",
            selector: (row) => row?.outlet?.name,
            sortable: true,
            // minWidth: "180px",
        },
        {
            name: "Invoice Date",
            selector: (row) => formatDateTime(row?.invDate, 'DD/MM/YYYY'),
            sortable: true,
            // minWidth: "180px",
        },
        {
            name: "Created at",
            selector: (row) => formatDateTime(row?.createdAt, 'DD/MM/YYYY hh:mm A'),
            sortable: true,
            // minWidth: "180px",
        },
        {
            name: "Updated at",
            selector: (row) => formatDateTime(row?.updatedAt, 'DD/MM/YYYY hh:mm A'),
            sortable: true,
            // minWidth: "180px",
        },
        {
            name: "Actions",
            button: true,
            width: "300px",
            cell: (row) => (
                <div className="d-flex gap-1">
                    <button onClick={() => { setCurrentData(row); handlePrintClick(); }} className='btn btn-warning pb-0 btn-style-light  btn-sm' title='Print'>
                        <span className="material-symbols-outlined">
                            print
                        </span>
                    </button>
                    {printInvoice && <InvoicePrinter onPrintComplete={handlePrintComplete} data={currentData} />}
                </div>
            )
        }
    ];

    const subHeaderComponent = React.useMemo(() => {
        return (
            <div className="d-flex gap-2">
                <Link className="btn btn-primary btn-sm d-flex align-items-center" to='/invoices/create'>
                    <i className="material-icons">add</i> <span className="ml-1">Create Invoice</span>
                </Link>
                {/* <button className="btn btn-dark btn-sm d-flex align-items-center" >
                    <i className="material-icons">filter_alt</i> <span className="ml-1">Filter</span>
                </button>  */}
            </div>
        );
    }, []);

    // console.log('data', data)
    return (
        <div>
            <DataTable
                columns={columns}
                data={data}
                direction="auto"
                fixedHeaderScrollHeight="300px"
                pagination
                responsive
                // progressPending={isLoading}
                subHeaderAlign="right"
                subHeaderWrap
                subHeaderComponent={subHeaderComponent}
                subHeader
                title='List of Invoices'
            />

        </div>
    )
}
