import React from 'react'
import Header from '../../components/Header/Header';
import { useUser } from '../../components/hooks/user';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useInvoicesList } from '../../components/hooks/invoices';
import PagesTable from './Comp/Table';
import { useSeoPagesList } from '../../components/hooks/seo';

export default function SEOManagementPage() {
    const { user } = useUser();
    const { seoPagesData, isLoadingSeoPages, filterSeoPages } = useSeoPagesList();
    // console.log(seoPagesData)
    return (
        <div>
            <Header user={user} />
            <Sidenav />
            <div className="content-wrapper mt-2">
                <div className="card mx-2">

                    <PagesTable data={seoPagesData} isLoading={isLoadingSeoPages} />
                </div>

            </div>
        </div>
    )
}
