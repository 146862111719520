import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import SelectField from '../../../components/Common/SelectField';
import FormikDatePicker from '../../../components/Common/FormikDatePicker';
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { stripResturantsList, useRestaurantsList } from '../../../components/hooks/restaurants';
import { LoadingButton } from '@mui/lab';
import TextField from '../../../components/Common/Fields';
import MyEditor from '../../../components/Common/MDXEditor';
// import MyEditor from '../../../components/Common/MDXEditor';
// import axios from 'axios';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const FILE_SIZE = 2 * 1024 * 1024; // 2MB

const validationSchema = Yup.object().shape({
    images: Yup.mixed()
        .test('fileSize', 'File size must be less than 2MB', value => !value || (value && value.size <= FILE_SIZE))
        .test('fileFormat', 'Only JPG, JPEG, and PNG formats are allowed', value => !value || (value && SUPPORTED_FORMATS.includes(value.type))).nullable(),
    pageTitle: Yup.string().required('Page Title is required'),
    content: Yup.string().required('Content is required'),
    altText: Yup.string(),
    metaTitle: Yup.string().required('Meta Title is required'),
    metaDescriptions: Yup.string().required('Meta Descriptions is required'),
    metaKeywords: Yup.string(),
    pageSlug: Yup.string()
        .matches(/^[a-z0-9-]+$/, 'Slug can only contain lowercase letters, numbers, and hyphens')
        .required('Slug is required'),
    tags: Yup.string(),
    isPublished: Yup.boolean(),
});

const AutoSlugGenerator = () => {
    const { values, setFieldValue, touched } = useFormikContext();

    React.useEffect(() => {
        if (values.pageTitle && !touched.pageSlug) {
            const slug = values.pageTitle
                .toLowerCase()
                .trim()
                .replace(/[\s]+/g, '-') // Replace spaces with hyphens
                .replace(/[^a-z0-9-]/g, ''); // Remove special characters
            setFieldValue("pageSlug", slug);
        }
    }, [values.pageTitle]);

    return null; // This component does not render anything
};
export default function AddPostForm({ data, handleFormSubmit, btnLabel, isLoading }) {



    return (
        <Formik
            initialValues={{
                images: null,
                pageTitle: data?.pageTitle ?? '',
                content: data?.content ?? '',
                altText: data?.altText ?? '',
                metaTitle: data?.metaTitle ?? '',
                metaDescriptions: data?.metaDescriptions ?? '',
                metaKeywords: data?.metaKeywords ?? '',
                pageSlug: data?.pageSlug ?? '',
                tags: data?.tags ?? '',
                isPublished: data?.isPublished ?? true,
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {formik => (

                <Form>
                    <AutoSlugGenerator />

                    <div className='row'>
                        <div className="col-md-6">
                            <label>Page Title</label>
                            <TextField label="Page Title" name="pageTitle" placeholder="Page Title" sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6">
                            <label>Page Slug</label>
                            <TextField label="Page Slug" name="pageSlug" placeholder="Page Slug" sx={{ mb: 3 }} />
                        </div>
                    </div>
                    <div className='row '>
                        <div className="col-md-6">
                            <label>Meta Title</label>
                            <TextField label="Meta Title" name="metaTitle" placeholder="Meta Title" sx={{ mb: 3 }} />
                        </div>
                        <div className="col-md-6">
                            <label>Meta Keywords</label>
                            <TextField label="Meta Keywords" name="metaKeywords" placeholder="Meta Keywords" sx={{ mb: 3 }} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <label>Meta Descriptions</label>
                            <TextField label="Meta Descriptions" name="metaDescriptions" placeholder="Meta Descriptions" sx={{ mb: 3 }} />
                        </div>

                        <div className="col-md-6">
                            <label>Tags</label>
                            <TextField label="Tags" name="tags" placeholder="Comma-separated tags" sx={{ mb: 3 }} />
                        </div>
                    </div>
                    <div className='row '>
                        <div className="col-md-12">
                            <MyEditor label="Content" name="content" placeholder="Content" />
                        </div>
                    </div>


                    <div className='row mt-3'>
                        <div className="col-md-6">
                            <label>Poster Image</label>
                            <input
                                id="images"
                                name="images"
                                type="file"
                                onChange={(event) => {
                                    formik.setFieldValue("images", event.currentTarget.files[0]);
                                }}
                                className="form-control"
                            />
                            <ErrorMessage name="images" component="div" className="text-danger" />

                        </div>
                        <div className="col-md-6">
                            <label>Poster Alt Text</label>
                            <TextField label="Alt Text" name="altText" placeholder="Alt Text" sx={{ mb: 3 }} />
                        </div>
                        
                        {/* Preview Image */}
                        <div className="col-md-6">
                            <img src={formik.values.images ? URL.createObjectURL(formik.values.images) : data?.bannerImage} alt="preview" className="img-fluid rounded  img-thumbnail" style={{ height: 200 }} />
                        </div>
                    </div>
                    <div className='row'>


                        <div className="col-md-6 d-flex align-items-center">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formik.values.isPublished}
                                        onChange={() => formik.setFieldValue("isPublished", !formik.values.isPublished)}
                                        color="primary"
                                    />
                                }
                                label="Published"
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center'>
                        <div>
                            <LoadingButton
                                loading={isLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                {btnLabel ? btnLabel : "Save post"}
                            </LoadingButton>
                            {/* <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => formik.resetForm()}>Clear Filter</Button> */}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}