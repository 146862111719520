import React, { useState } from "react";
import Viewitems from "../Item/Viewitems";
import UpdateRestaurant from "../model/UpdateRestaurant";
import AddItemModel from "../model/AddItemModel";
import { Switch } from "@mui/material";
import { useUpdateRest } from "../hooks/restaurants";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadModel from "../model/UploadMenuModel";
import { useUser } from "../hooks/user";
import TagToIrctcModal from "../model/TagToIrctcModal";
import ScheduleClosed from "../model/ScheduleClosed";
import ResturantImageModal from "../model/ResturantImageModal";
import moment from "moment";
import AddPromotionModal from "./Comp/AddPromotionModal";
import { handleExportMenu } from "./actions";

const RestaurantInformation = ({ data }) => {
    const [flag, setFlag] = useState(false);
    // console.log("resdata", data)
    const [promotionAddModal, setPromotionAddModal] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [TagModalIsOpen, setTagModalIsOpen] = useState(false);

    const [ScheduleClosedIsOpen, setScheduleClosedIsOpen] = useState(false);
    const [modaltwo, setModalTwo] = useState(false);
    const [updateImageIsOpen, setUpdateImageIsOpen] = useState(false);
    const { user } = useUser();
    // const [status, setStatus] = useState(data.status);
    const { id } = useParams();
    const { handleUpdateRest } = useUpdateRest(id);
    // console.log(data)
    const updateStatus = async (val) => {
        const res = await handleUpdateRest({ status: val });
        if (res.success) {
            toast.success("Status Updated");
            // window.location.reload();
        } else {
            toast.error(res.error);
        }
    };
    const changeFlag = () => {
        if (flag) {
            setFlag(false);
        } else {
            setFlag(true);
        }
    };


    const handleImportMenu = async (file, id) => {
        if (!id || !file) {
            console.log('id or file is missing');
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        const url = `https://api.yatrirestro.com/api/v1/item/upload/${id}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            console.log('import menu data', data);
            if (data.success) {
                toast.success('Menu imported successfully');
            } else {
                toast.error(data.error);
            }
        } catch (error) {
            // console.error('There was a problem with the fetch operation:', error);
            toast.error('There was a problem with the fetch operation');
        }
    }


    return (
        <>
            <div className="wrapper">
                <div>
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <div className="container-fluid"></div>
                        {/* /.container-fluid */}
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    {/* Profile Image */}
                                    <div className="card card-primary card-outline">
                                        <div className="card-body box-profile">
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <span
                                                    className={
                                                        data.status === "Active"
                                                            ? "badge badge-success"
                                                            : "badge badge-danger"
                                                    }
                                                >
                                                    {data.status}
                                                </span>
                                                <Switch
                                                    defaultChecked={
                                                        data.status === "Active" ? true : false
                                                    }
                                                    color="secondary"
                                                    onChange={(e) =>
                                                        updateStatus(
                                                            data.status === "Active" ? "Closed" : "Active"
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="text-center">
                                                <div>{data.logo ? <img
                                                    className="profile-user-img img-fluid img-circle"
                                                    src={data.logo}
                                                    alt="User"
                                                /> : <img
                                                    className="profile-user-img img-fluid img-circle"
                                                    src="../../dist/img/resturant.jpg"
                                                    alt="User"
                                                />}

                                                </div>
                                            </div>
                                            <h3 className="profile-username text-center">
                                                {data.name}
                                            </h3>
                                            <p className="text-muted text-center">{data.brand}</p>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Restaurant Type</b>{" "}
                                                    <span
                                                        className="float-right"
                                                        style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {data.restaurant_type.map((e) => {
                                                            return (
                                                                <span
                                                                    className={
                                                                        e === "Non-Veg"
                                                                            ? "badge badge-danger"
                                                                            : "badge badge-success"
                                                                    }
                                                                >
                                                                    {e}
                                                                </span>
                                                            );
                                                        })}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Address</b>{" "}
                                                    <span className="float-right">{data.address}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>ID</b>{" "}
                                                    <span className="float-right">{data.restoId}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Minimum Order Amount</b>{" "}
                                                    <span className="float-right">
                                                        {data.min_order_amount
                                                            ? `${data.min_order_amount.$numberDecimal} Rs.`
                                                            : ""}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Minimum Order Time</b>{" "}
                                                    <span className="float-right">
                                                        {data.min_order_time}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Weekly Closed</b>{" "}
                                                    <span className="float-right">
                                                        {data.weeklyClosed ? data.weeklyClosed.map((e) => {
                                                            return (
                                                                <span
                                                                >
                                                                    {e},
                                                                </span>
                                                            );
                                                        }) : ""}
                                                    </span>
                                                </li>
                                            </ul>
                                            {data?.promotions?.discount?.type && <p>Promotion ({data?.promotions?.discount?.type}): <small>Value: {data?.promotions?.discount?.value}</small> <small>Max: {data?.promotions?.discount?.maxDiscount}</small></p>}
                                        </div>
                                    </div>

                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Contact Details</h3>
                                        </div>

                                        <div className="card-body">
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Contact Person</b>{" "}
                                                    <span className="float-right">
                                                        {data.contact_details.contact_person}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Contact Number</b>{" "}
                                                    <span className="float-right">
                                                        {data.contact_details.contact_number}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Contact Email</b>{" "}
                                                    <span className="float-right">
                                                        {data.contact_details.email}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Alternate Number</b>{" "}
                                                    <span className="float-right">
                                                        {data.contact_details.alt_number}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">More Details</h3>
                                        </div>
                                        <div className="card-body">
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Firm Name</b>{" "}
                                                    <span className="float-right">{data.firm_name}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Firm Type</b>{" "}
                                                    <span className="float-right">{data.firm_type}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Fssai Lic Number</b>{" "}
                                                    <span className="float-right">
                                                        {data.fssai_lic_num}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Fssai Status</b>{" "}
                                                    <span className="float-right">
                                                        {data.fssai_status === true ? "Yes" : "No"}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Gstin</b>{" "}
                                                    <span className="float-right">{data.gstin}</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Closing Time</b>{" "}
                                                    <span className="float-right">
                                                        {data.closing_time}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Opening Time</b>{" "}
                                                    <span className="float-right">
                                                        {data.opening_time}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Delivery Type</b>{" "}
                                                    <span className="float-right">
                                                        {data.delivery_type}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Station Distance</b>{" "}
                                                    <span className="float-right">
                                                        {data.station_distance}
                                                    </span>
                                                </li>

                                                <li className="list-group-item">
                                                    <b>Rating</b>{" "}
                                                    <span className="float-right">{data.rating}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Station Details</h3>
                                        </div>
                                        <div className="card-body">


                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">City</th>
                                                        <th scope="col">Station name</th>
                                                        <th scope="col">Station Code</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.station.map((e, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{e.city}</td>
                                                                <td>{e.stationName}</td>
                                                                <td>{e.stationCode}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <p>Balance</p>
                                                <h2 style={{ color: "gold", fontSize: "42px" }}>
                                                    {data.wallet.$numberDecimal}
                                                </h2>
                                            </div>
                                            <div>
                                                <p>Actions</p>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    {user.role === "admin" ||
                                                        user.role === "superadmin" ? (
                                                        <button
                                                            className="btn btn-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalUpload"
                                                            data-whatever="@getbootstrap"
                                                            type="button"
                                                        >
                                                            Upload Menu <i className="fa fa-bars"></i>
                                                        </button>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <button
                                                        className="btn btn-primary"
                                                        // data-bs-toggle="modal"
                                                        // data-bs-target="#exampleModaltwo"
                                                        // data-whatever="@getbootstrap"
                                                        type="button"
                                                        onClick={() => setModalIsOpen(true)}
                                                    >
                                                        Update <i className="fa fa-refresh"></i>
                                                    </button>

                                                    <button
                                                        className="btn btn-info"
                                                        // data-bs-toggle="modal"
                                                        // data-bs-target="#exampleModaltwo"
                                                        // data-whatever="@getbootstrap"
                                                        type="button"
                                                        onClick={() => handleExportMenu(data?.name, data?._id)}
                                                    >
                                                        Export Menu
                                                    </button>
                                                    {/* Import menu */}
                                                    <input type="file" onChange={(e) => handleImportMenu(e.target.files[0], data?._id)} className='btn btn-info' />

                                                    {!flag ? (
                                                        <button
                                                            className="btn btn-primary"
                                                            type="button"
                                                            data-bs-toggle="button"
                                                            onClick={changeFlag}
                                                        >
                                                            View item <i className="fa fa-eye"></i>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-dark"
                                                            data-bs-toggle="button"
                                                            onClick={changeFlag}
                                                        >
                                                            Hide item <i className="fa fa-eye-slash"></i>
                                                        </button>
                                                    )}
                                                    <button
                                                        className="btn btn-success"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        data-whatever="@getbootstrap"
                                                        onClick={() => setModalTwo(true)}
                                                    >
                                                        Add Item <i className="fa fa-plus"></i>
                                                    </button>
                                                    {
                                                        user.role === 'admin' || user.role === 'superadmin' || user.role === 'manager' ?
                                                            <button
                                                                className="btn btn-success"
                                                                // data-bs-toggle="modal"
                                                                // data-bs-target="#exampleModalTag"
                                                                // data-whatever="@getbootstrap"
                                                                type="button"
                                                                onClick={() => setTagModalIsOpen(true)}
                                                            >
                                                                Tag to IRCTC <i className="fa fa-tag"></i>
                                                            </button> : ""
                                                    }
                                                    {
                                                        user.role === 'admin' || user.role === 'superadmin' || user.role === 'manager' ?
                                                            <button
                                                                className="btn btn-success"
                                                                // data-bs-toggle="modal"
                                                                // data-bs-target="#exampleModalTag"
                                                                // data-whatever="@getbootstrap"
                                                                type="button"
                                                                onClick={() => setScheduleClosedIsOpen(true)}
                                                            >
                                                                Schedule Closed <i className="fa fa-lock"></i>
                                                            </button> : ""
                                                    }
                                                    {
                                                        user.role === 'admin' || user.role === 'superadmin' || user.role === 'manager' ?
                                                            <button
                                                                className="btn btn-success"
                                                                // data-bs-toggle="modal"
                                                                // data-bs-target="#exampleModalUpdateImage"
                                                                // data-whatever="@getbootstrap"
                                                                type="button"
                                                                onClick={() => setUpdateImageIsOpen(true)}
                                                            >
                                                                Update Image <i className="fa fa-upload"></i>
                                                            </button> : ""
                                                    }
                                                    {
                                                        ['admin', 'superadmin', 'manager'].includes(user.role) && (
                                                            <button
                                                                className="btn btn-warning"
                                                                type="button"
                                                                onClick={() => setPromotionAddModal(true)}
                                                            >
                                                                Promotion <i className="fa fa-bullhorn" />
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Bank Details</h3>
                                        </div>
                                        <div className="card-body">
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item">
                                                    <b>Account Holder Name</b>{" "}
                                                    <span className="float-right">
                                                        {data.bank_ac_details.ac_holder_name}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>Account Number</b>{" "}
                                                    <span className="float-right">
                                                        {data.bank_ac_details.account}
                                                    </span>
                                                </li>
                                                <li className="list-group-item">
                                                    <b>IFSC</b>{" "}
                                                    <span className="float-right">
                                                        {data.bank_ac_details.ifsc}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {flag ? (
                    <div>

                        <Viewitems id={data._id} />{" "}
                    </div>
                ) : null}
            </div>

            <UpdateRestaurant
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                data={data}
            />

            {promotionAddModal && <AddPromotionModal data={data} modal={promotionAddModal} setModal={setPromotionAddModal} />}

            <AddItemModel modaltwo={modaltwo} setModalTwo={setModalTwo} />

            <UploadModel
                id="exampleModalUpload"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            />
            <TagToIrctcModal
                isOpen={TagModalIsOpen}
                setOpen={setTagModalIsOpen}
                data={data.station}
                resId={data._id}
            />

            <ScheduleClosed
                isOpen={ScheduleClosedIsOpen}
                setOpen={setScheduleClosedIsOpen}
                resId={data._id}
            />
            <ResturantImageModal
                isOpen={updateImageIsOpen}
                setOpen={setUpdateImageIsOpen}
                resId={data._id}
            />
        </>
    );
};

export default RestaurantInformation;
